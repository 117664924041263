import {
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { QuestionSlice } from "../../../Redux/reducers/responseSlice";
import { useParams } from "react-router-dom";
import GridDates from "./GridDates";
import { useMemo } from "react";
import {
  handleGridChange,
  triggerGridAggregate,
  triggerSave,
} from "../../../Redux/actions/responseEventAction";

type Props = {
  currentQuestion: QuestionSlice;
};

export type Sigh = Props & {
  rowIndex: number;
  colIndex: number;
  handleChange: (
    value: any,
    rowIndex: number,
    colIndex: number,
    key: string
  ) => void;
  dateFormat?: string;
  isGrid?: boolean;
  isPreview?: boolean;
  rowAggregation?: string;
  columnAggregation?: string;
  handleBlur?: Function;
};

const InputField = ({
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
}: Sigh) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.gridData?.[rowIndex]?.[colIndex] || "";

  return (
    <TextField
      name="textValue"
      fullWidth
      sx={{ width: 200 }}
      placeholder="Enter Value"
      value={currentResponse?.textValue || ""}
      onChange={(e) =>
        handleChange(e.target.value, rowIndex, colIndex, "textValue")
      }
      InputProps={{ readOnly: isPreview }}
    />
  );
};

const NumberField = ({
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
  rowAggregation,
  columnAggregation,
  handleBlur,
}: Sigh) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.gridData?.[rowIndex]?.[colIndex] || "";

  const handleBlurTrigger = () => {
    if (!isPreview && (!!rowAggregation || !!columnAggregation)) {
      handleBlur &&
        handleBlur(rowIndex, colIndex, rowAggregation, columnAggregation);
    }
  };

  return (
    <TextField
      type="number"
      name="numberValue"
      // fullWidth
      sx={{ display: "flex", minWidth: 125, maxWidth: "100%" }}
      placeholder="Enter Numeric Value"
      value={currentResponse.numberValue ?? ""}
      onChange={(e) =>
        handleChange(e.target.value, rowIndex, colIndex, "numberValue")
      }
      onBlur={handleBlurTrigger}
      InputProps={{ readOnly: isPreview }}
    />
  );
};

const SelectField = ({
  choices,
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
}: Sigh & { choices: string[] }) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.gridData?.[rowIndex]?.[colIndex] || "";

  return (
    <Select
      fullWidth
      sx={{ width: 200 }}
      id="position"
      value={currentResponse.textValue || ""}
      onChange={(e) => {
        handleChange(e.target.value, rowIndex, colIndex, "textValue");
      }}
      readOnly={isPreview}
    >
      {choices?.map((choice, i) => (
        <MenuItem key={i} value={choice}>
          {choice}
        </MenuItem>
      ))}
    </Select>
  );
};

type AggregateProps = {
  currentQuestion: QuestionSlice;
  index: number;
  type: "row" | "column";
  aggregateType: string;
};
const AggregateField: React.FC<AggregateProps> = ({
  currentQuestion,
  index,
  type,
  aggregateType,
}) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.aggregateData?.[type]?.[index]?.numberValue ?? 0;
  return (
    <>
      {aggregateType} : {currentResponse}
    </>
  );
};

const GridField = ({ currentQuestion }: Props) => {
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.formEditable);

  const { properties } = currentQuestion;
  const gridConfig = properties?.gridConfig;

  const rowAggregate = useMemo(() => {
    return gridConfig?.rows?.some((row: any) => !!row.aggregateType);
  }, [gridConfig]);

  const columnAggregate = useMemo(() => {
    return gridConfig?.columns?.some((col: any) => !!col.aggregateType);
  }, [gridConfig]);

  const handleChange = (
    value: any,
    rowIndex: number,
    colIndex: number,
    key: string
  ) => {
    dispatch(
      handleGridChange({
        key,
        value: value,
        id: currentQuestion.id,
        rowIndex,
        colIndex,
      })
    );
  };

  const triggerAggregate = (
    rowIndex: number,
    colIndex: number,
    rowAggregation: string,
    columnAggregation: string
  ) => {
    dispatch(
      triggerGridAggregate({
        id: currentQuestion.id,
        rowIndex,
        colIndex,
        rowAggregation,
        columnAggregation,
      })
    );
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md: "100%",
        },
        pl: "60px",
      }}
      gap={1}
    >
      {gridConfig?.fieldType === "row" && (
        <TableContainer className="grid-table-container">
          <Table
            aria-label="simple table"
            sx={{
              maxWidth: "100%",
              width: "auto",
            }}
          >
            <TableHead sx={{ bgcolor: "secondary.main" }}>
              <TableRow>
                <TableCell> </TableCell>
                {gridConfig?.columns.map((col, index) => (
                  <TableCell key={col.label + index} component="th" scope="row">
                    {col.label}
                  </TableCell>
                ))}
                {rowAggregate && (
                  <TableCell key={"row-aggregate"} component="th" scope="row">
                    Aggregate
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& td": {
                  padding: "0px",
                },
              }}
            >
              {gridConfig?.rows.map((row, i) => (
                <TableRow
                  key={row.label + i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ bgcolor: "secondary.main", minWidth: "100px" }}
                  >
                    {row.label}
                  </TableCell>

                  {gridConfig?.columns.map((col, j) => (
                    <TableCell key={i + j} component="td" scope="row">
                      {row.type === "text" ? (
                        <InputField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isPreview={isPreview}
                        />
                      ) : row.type === "number" ? (
                        <NumberField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isPreview={isPreview}
                          rowAggregation={row.aggregateType}
                          columnAggregation={col.aggregateType}
                          handleBlur={triggerAggregate}
                        />
                      ) : row.type === "date" ? (
                        <GridDates
                          rowIndex={i}
                          colIndex={j}
                          dateFormat={row?.format}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isGrid={true}
                          isPreview={isPreview}
                        />
                      ) : (
                        <SelectField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          choices={row.options || []}
                          isPreview={isPreview}
                        />
                      )}
                    </TableCell>
                  ))}
                  {rowAggregate && (
                    <TableCell
                      sx={{
                        textTransform: "capitalize",
                        textAlign: !row.aggregateType ? "center" : "auto",
                      }}
                    >
                      {row.aggregateType ? (
                        <AggregateField
                          currentQuestion={currentQuestion}
                          index={i}
                          type={"row"}
                          aggregateType={row.aggregateType}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {columnAggregate && (
                <TableRow
                  key={"col-aggregate"}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      bgcolor: "secondary.main",
                      minWidth: "100px",
                    }}
                  >
                    Aggregate
                  </TableCell>

                  {gridConfig?.columns.map((item: any, j: number) => (
                    <TableCell
                      key={j}
                      sx={{
                        textTransform: "capitalize",
                        textAlign: !item.aggregateType ? "center" : "auto",
                      }}
                    >
                      {item.aggregateType ? (
                        <AggregateField
                          currentQuestion={currentQuestion}
                          index={j}
                          type={"column"}
                          aggregateType={item.aggregateType}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  ))}

                  {rowAggregate && <TableCell></TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {gridConfig?.fieldType === "column" && (
        <TableContainer className="grid-table-container">
          <Table
            aria-label="simple table"
            sx={{
              maxWidth: "100%",
              width: "auto",
            }}
          >
            <TableHead sx={{ bgcolor: "secondary.main" }}>
              <TableRow>
                <TableCell> </TableCell>
                {gridConfig?.columns.map((col, i) => (
                  <TableCell key={i} component="th" scope="row">
                    {col.label}
                  </TableCell>
                ))}
                {rowAggregate && (
                  <TableCell key={"row-aggregate"} component="th" scope="row">
                    Aggregate
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& td": {
                  padding: "0px",
                },
              }}
            >
              {gridConfig?.rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ bgcolor: "secondary.main", minWidth: "100px" }}
                  >
                    {row.label}
                  </TableCell>

                  {gridConfig?.columns.map((col, j) => (
                    <TableCell key={col.label + j} component="td" scope="row">
                      {col.type === "text" ? (
                        <InputField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isPreview={isPreview}
                        />
                      ) : col.type === "number" ? (
                        <NumberField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isPreview={isPreview}
                          rowAggregation={row.aggregateType}
                          columnAggregation={col.aggregateType}
                          handleBlur={triggerAggregate}
                        />
                      ) : col.type === "date" ? (
                        <GridDates
                          rowIndex={i}
                          colIndex={j}
                          dateFormat={col?.format}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          isGrid={true}
                          isPreview={isPreview}
                        />
                      ) : (
                        <SelectField
                          rowIndex={i}
                          colIndex={j}
                          currentQuestion={currentQuestion}
                          handleChange={handleChange}
                          choices={col.options || []}
                          isPreview={isPreview}
                        />
                      )}
                    </TableCell>
                  ))}

                  {rowAggregate && (
                    <TableCell
                      sx={{
                        textTransform: "capitalize",
                        textAlign: !row.aggregateType ? "center" : "auto",
                      }}
                    >
                      {row.aggregateType ? (
                        <AggregateField
                          currentQuestion={currentQuestion}
                          index={i}
                          type={"row"}
                          aggregateType={row.aggregateType}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}

              {columnAggregate && (
                <TableRow
                  key={"col-aggregate"}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      bgcolor: "secondary.main",
                      minWidth: "100px",
                    }}
                  >
                    Aggregate
                  </TableCell>

                  {gridConfig?.columns.map((item: any, j: number) => (
                    <TableCell
                      key={j}
                      sx={{
                        textTransform: "capitalize",
                        textAlign: !item.aggregateType ? "center" : "auto",
                      }}
                    >
                      {item.aggregateType ? (
                        <AggregateField
                          currentQuestion={currentQuestion}
                          index={j}
                          type={"column"}
                          aggregateType={item.aggregateType}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  ))}

                  {rowAggregate && <TableCell></TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <GridSave id={currentQuestion.id} />
    </Stack>
  );
};

const GridSave = ({ id }: { id: string }) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.formEditable);
  const isFieldSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  const handleBlur = () => {
    if (id) {
      dispatch(
        triggerSave({
          studyId,
          id: id,
          surveySlug,
        })
      );
    }
  };

  return (
    <Button
      disabled={!!isFieldSubmitting || isPreview}
      variant="contained"
      onClick={handleBlur}
      sx={{ width: "fit-content", alignSelf: "flex-end" }}
    >
      Save
    </Button>
  );
};

export default GridField;
