import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setFeaturesLoader,
  setFeaturesCount,
  setFeaturesDetails,
} from "../reducers/featuresSlice";
import { AppThunk } from "../store";

export const fetchFeaturesList =
  (
    sortOrder: string,
    sortColumn: string,
    category: string,
    tag: string,
    status: string,
    searchText: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFeaturesLoader(true));
      let url: string = `/features?`;
      if (searchText) {
        url += `&search=${searchText}`;
      }

      if (sortOrder && sortColumn) {
        url += `&sortOrder=${sortOrder}&sortKey=${sortColumn}`;
      }
      if (category) {
        url += `&category=${category}`;
      }
      if (tag) {
        url += `&tag=${tag}`;
      }
      if (status) {
        url += `&status=${status}`;
      }

      const res: AxiosResponse = await http.get(url);
      const data = res?.data?.data?.rows;

      const newData = data?.map((item: any) => {
        return {
          id: item?.id,
          expanded: false,
          featureName: item?.name || "-",
          category: item?.category || "-",
          helperText: item?.helperText || "-",
          tags: item?.tags || [],
          tagString:
            item?.tags.length > 0
              ? item?.tags.map((tag: any) => tag.name).join(", ")
              : "-",
          status: item?.status || "-",
          childrenFeatures:
            item?.childrenFeatures.length > 0
              ? item?.childrenFeatures.map((child: any) => ({
                  id: child?.id,
                  featureName: child?.name || "-",
                  category: child?.category || "-",
                  helperText: child?.helperText || "-",
                  tags: child?.tags || [],
                  tagString:
                    child?.tags?.length > 0
                      ? child?.tags?.map((tag: any) => tag.name).join(", ")
                      : "-",
                  status: child?.status || "-",
                }))
              : [],
        };
      });

      dispatch(setFeaturesDetails(newData));
      dispatch(setFeaturesCount(res?.data?.data?.count));
      dispatch(setFeaturesLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setFeaturesLoader(false));
    }
  };
