import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  Button,
  TextField,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import {
  ModalDrawerButtonStyles,
  ModalDrawerStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { DrawerForm } from "../Common/styles/drawer";
import { ClearIcon } from "@mui/x-date-pickers";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

let schema = yup.object().shape({
  label: yup.string().optional(),
  description: yup.string().optional(),
  linkedFeatureId: yup.string().optional(),
});

const EditPermissionModal = ({
  data,
  showModal,
  closeModal,
  refreshPage,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [featuresList, setFeaturesList] = useState<any[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/features`);
        const newData = res?.data?.data?.rows?.map((feature: any) => ({
          value: feature?.id,
          label: feature?.name,
        }));
        setFeaturesList(newData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchCountries();
  }, []);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);

      const body: any = {
        label: values?.label,
        description: values?.description,
        linkedFeatureId: values?.linkedFeatureId || null,
      };
      let res: AxiosResponse;
      res = await http.patch(`/admin/permission/${data?.id}`, body);

      toastMessage("success", res.data.message);
      closeModal();
      setButtonLoader(false);
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalDrawerStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Edit Permission"} onCloseClick={closeModal} />
        {!loading ? (
          <Formik
            initialValues={{
              label: data?.label || "",
              description: data?.description || "",
              linkedFeatureId: data?.linkedFeatureId || "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({ errors, touched, getFieldProps, setFieldValue, values }) => (
              <Form style={DrawerForm}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="label">
                    Label
                  </FormLabel>
                  <TextField
                    id="label"
                    placeholder="Label"
                    {...getFieldProps("label")}
                    error={touched?.label && errors?.label ? true : false}
                    helperText={
                      touched?.label && errors?.label
                        ? (errors?.label as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="description">
                    Description
                  </FormLabel>
                  <TextField
                    id="description"
                    placeholder="Description"
                    {...getFieldProps("description")}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? (errors?.description as string)
                        : " "
                    }
                  />
                </FormControl>

                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="link-feature">
                    Link Feature
                  </FormLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "300px", overflowY: "auto" },
                      },
                    }}
                    value={values?.linkedFeatureId || ""}
                    onChange={(e) =>
                      setFieldValue("linkedFeatureId", e.target.value)
                    }
                    endAdornment={
                      values.linkedFeatureId !== "" && (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          <IconButton
                            onClick={() => {
                              setFieldValue("linkedFeatureId", "");
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {featuresList?.map((item: any) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.linkedFeatureId && errors?.linkedFeatureId
                      ? (errors?.linkedFeatureId as string)
                      : " "}
                  </FormHelperText>
                </FormControl>

                <Box mt={"auto"}>
                  <Divider />
                  <Box sx={ModalDrawerButtonStyles}>
                    {!buttonLoader ? (
                      <>
                        <Button variant="outlined" onClick={closeModal}>
                          Cancel
                        </Button>
                        <Button variant="contained" type="submit">
                          Save
                        </Button>
                      </>
                    ) : (
                      <CircularProgress size={25} />
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditPermissionModal;
