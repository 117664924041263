import { Backdrop } from "@mui/material";
import { useCallback, useEffect } from "react";

type Props = {
  closeHandler: Function;
  image: string;
};
const ImagePreview = ({ closeHandler, image }: Props) => {
  const onClose = useCallback(() => {
    closeHandler();
  }, [closeHandler]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <Backdrop
      open={true}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      onClick={onClose}
    >
      <img
        style={{ maxHeight: "90vh", maxWidth: "90vw" }}
        src={image}
        alt={"preview"}
      />
    </Backdrop>
  );
};

export default ImagePreview;
