import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import FeaturesList from "./Tabs/FeaturesList";
import FeatureUrlLoader from "./FeatureUrlLoader";
import FeatureUrlSetter from "./FeatureUrlSetter";
import {
  a11yProps,
  CustomTabPanel,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../Common/UI/TabPanel";
import Permissions from "./Tabs/Permissions";

const Features = () => {
  const [showModal, setShowModal] = useState<string>("");
  const [type, setType] = useState("features");
  const [urlLoaded, setUrlLoaded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
  };
  return urlLoaded ? (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={20} fontWeight="medium" color="text.primary">
            Features
          </Typography>
        </Box>

        <Box sx={HeaderRightContent}>
          {type === "features" && (
            <Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => setShowModal("feature")}
              >
                New Feature
              </Button>
            </Box>
          )}
          {/* {type === "permissions" && (
            <Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => setShowModal("permissions")}
              >
                Add Permission
              </Button>
            </Box>
          )} */}
        </Box>
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Features" value="features" {...a11yProps(0)} />
          <StyledTab
            label="Permissions"
            value="permissions"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </Box>
      <Box>
        <Box sx={{ height: "calc(100vh - 137px)", overflow: "auto" }}>
          <MainTabPanel value={type} index={"features"}>
            <FeaturesList
              type={type}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </MainTabPanel>

          <CustomTabPanel value={type} index={"permissions"}>
            <Permissions
              type={type}
              showModal={showModal}
              setShowModal={setShowModal}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </CustomTabPanel>
        </Box>
      </Box>
      <FeatureUrlSetter type={type} searchText={searchText} />
    </>
  ) : (
    <FeatureUrlLoader
      setType={setType}
      setSearchText={setSearchText}
      setUrlLoaded={setUrlLoaded}
    />
  );
};

export default Features;
