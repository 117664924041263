import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuestionState {
  // Basic
  questionName: string;
  error: string;
  loading: boolean;
  editable: boolean;
  formSubmitting: boolean;

  status: string; // Unused X
  formDirty: boolean; // Unused X

  // Modal Props
  showPropModal: boolean;
  modalQuestion: any;
  mainQuestion?: any;

  // Tab Validation
  isBasicError: string;
  isChoicesError: string;
  isRepeatedConfigError: string;
  isGridConfigError: string;
  isValidationsError: string;

  // Currently In Use
  questions: any[];
  percentage: number;
  formId: string; //FormId
  studyId: string;
  lang: string;

  // question validation
}

export const initialQuestionState: QuestionState = {
  // Basic
  questionName: "",
  error: "",
  loading: true,
  editable: true,
  formSubmitting: false,

  status: "", // Unused X
  formDirty: false, // Unused X

  //Modal Props
  showPropModal: false,
  modalQuestion: null,
  mainQuestion: null,

  // Tab Validation
  isBasicError: "",
  isChoicesError: "",
  isRepeatedConfigError: "",
  isGridConfigError: "",
  isValidationsError: "",

  // Currently In Use
  questions: [],
  percentage: 0,
  formId: "", //FormId
  studyId: "",
  lang: "",
};

export const questionTranslationSlice = createSlice({
  name: "questionTranslation",
  initialState: initialQuestionState,
  reducers: {
    reset: () => initialQuestionState,
    setQuestionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setQuestionLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setQuestionSubmitting: (state, action: PayloadAction<boolean>) => {
      state.formSubmitting = action.payload;
    },
    resetQuestionState: (state) => {
      state.questionName = "";
      state.error = "";
      state.loading = true;
      state.editable = true;
      state.formSubmitting = false;

      state.status = "";
      state.formDirty = false;

      state.showPropModal = false;
      state.modalQuestion = null;
      state.mainQuestion = null;

      state.questions = [];
      state.formId = "";
      state.studyId = "";
      state.lang = "";
    },
    setQuestionnaireDetails: (state, action: PayloadAction<QuestionState>) => {
      state.questionName = action.payload.questionName;
      state.error = action.payload.error;
      state.loading = action.payload.loading;
      state.editable = action.payload.editable;
      state.formSubmitting = action.payload.formSubmitting;

      state.status = action.payload.status;
      state.formDirty = action.payload.formDirty;

      state.showPropModal = action.payload.showPropModal;
      state.modalQuestion = action.payload.modalQuestion;

      state.questions = action.payload.questions;
      state.percentage = action.payload.percentage;
      state.formId = action.payload.formId;
      state.studyId = action.payload.studyId;
      state.lang = action.payload.lang;
    },
    setQuestionModalDetails: (
      state,
      action: PayloadAction<{
        show: boolean;
        question: any;
        mainQuestion?: any;
      }>
    ) => {
      state.showPropModal = action.payload.show;
      state.modalQuestion = action.payload.question;
      state.mainQuestion = action.payload.mainQuestion;
    },
    handleChoiceModification: (
      state,
      action: PayloadAction<{
        currentRef: string;
        section: "label" | "imageUrl" | "previewUrl";
        value: any;
      }>
    ) => {
      const { currentRef, section, value } = action.payload;
      state.modalQuestion.choices.forEach((c: any) => {
        if (c.questionChoiceId === currentRef) {
          let val = value;

          c[section] = val;
        }
      });
    },
    handleTabErrors: (
      state,
      action: PayloadAction<{
        errorHandler: {
          basic: string;
          choices: string;
          repeatedConfig: string;
          gridConfig: string;
          validations: string;
        };
      }>
    ) => {
      const { basic, choices, repeatedConfig, gridConfig, validations } =
        action.payload.errorHandler;

      state.isBasicError = basic;
      state.isChoicesError = choices;
      state.isRepeatedConfigError = repeatedConfig;
      state.isGridConfigError = gridConfig;
      state.isValidationsError = validations;
    },
    handleBasicsChange: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
        isProp?: boolean;
        convertToNum?: boolean;
      }>
    ) => {
      const { key, value, isProp, convertToNum } = action.payload;

      let val = convertToNum ? parseFloat(value) : value;
      if (convertToNum && isNaN(val as number)) {
        val = "";
      }

      if (isProp) {
        state.modalQuestion.properties[key] = val;
        return;
      }

      state.modalQuestion[key] = val;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
  },
});

export const {
  setQuestionnaireDetails,
  setQuestionLoader,
  setQuestionSubmitting,
  setQuestionError,
  setQuestionModalDetails,
  handleChoiceModification,
  handleTabErrors,
  handleBasicsChange,
  resetQuestionState,
  setLang,
  reset,
} = questionTranslationSlice.actions;

export default questionTranslationSlice.reducer;
