import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const getDefault = (param: string) => {
  return param === "features" || param === "permissions" ? param : "features";
};
type Props = {
  setType: React.Dispatch<React.SetStateAction<string>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setUrlLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

const FeatureUrlLoader = ({ setType, setSearchText, setUrlLoaded }: Props) => {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    setType(getDefault(searchParams.get("type") || "features"));
    setSearchText(searchParams.get("search") || "");
    setUrlLoaded(true);
  }, [searchParams, setType, setSearchText, setUrlLoaded]);

  return <></>;
};

export default React.memo(FeatureUrlLoader);
