import { Box, Typography } from "@mui/material";
import { AuthLayout, SignInWrapper } from "../Auth/auth.style";

const SurveyLinkSent = () => {
  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Typography
          sx={{ fontSize: "30px", fontWeight: 600, color: "text.primary" }}
        >
          Survey Link Sent!
        </Typography>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 500, color: "text.secondary" }}
        >
          We have sent an email to your email address. Please check your email.
        </Typography>
      </Box>
    </Box>
  );
};

export default SurveyLinkSent;
