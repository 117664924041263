import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { SearchBox } from "../../../Organisations/Organisation";
import { NoDataContainer, StyledTableCell } from "../../../Common/styles/table";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { MoreVert } from "@mui/icons-material";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { AxiosResponse } from "axios";
import EditPermissionModal from "../../EditPermissionModal";

type Props = {
  type: string;
  childTab: string;
  showModal: string;
  setShowModal: Function;
  searchText: string;
  setSearchText: Function;
};

const Study = ({
  type,
  childTab,
  showModal,
  searchText,
  setShowModal,
  setSearchText,
}: Props) => {
  //   const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [permissionData, setPermissionData] = useState<any>([]);
  const [permissionCount, setPermissionCount] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/admin/permission?&type=${childTab}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.map((row: any) => ({
          id: row?.id,
          label: row?.label || "-",
          description: row?.description || "-",
          linkedFeature: row?.linkedFeature?.name || "-",
          linkedFeatureId: row?.linkedFeature?.id,
          code: row?.code || "-",
          status: row?.isPublished ? "Published" : "Draft",
        }));
        setPermissionData(newData || []);
        setPermissionCount(data?.length || 0);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [childTab, toggleLoader, searchText]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );
  //   const openFilterModal = () => {
  //     setShowFilterModal(true);
  //   };
  //   const closeFilterModal = () => {
  //     setShowFilterModal(false);
  //   };

  const openModal = (type: string) => {
    setShowModal(type);
  };

  const closeModal = () => {
    setShowModal("");
    setSelectedRow(null);
  };

  const publishPermission = async () => {
    try {
      setMenuLoader(true);
      const res = await http.post(
        `/admin/permission/${selectedRow?.id}/publish`
      );
      handleMenuClose();
      toastMessage("success", res.data.message);
      setMenuLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };

  return (
    <Box>
      <Box>
        <Box sx={SearchBox}>
          <TextField
            sx={{ width: "70%" }}
            variant="outlined"
            placeholder="Search"
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#637E85" }} />
                </InputAdornment>
              ),
            }}
          />
          {/* <Box sx={{ ...filterBox, width: "30%" }} onClick={openFilterModal}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <SortRounded />
              <Typography>Filter</Typography>
            </Box>
            <ExpandMoreRounded />
          </Box> */}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Label</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Feature</StyledTableCell>
              <StyledTableCell>Code</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>

          {!loading && permissionCount > 0 && (
            <TableBody>
              {permissionData?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.label}</StyledTableCell>
                  <StyledTableCell>{row?.description}</StyledTableCell>
                  <StyledTableCell>{row?.linkedFeature}</StyledTableCell>
                  <StyledTableCell>{row?.code}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color:
                        row?.status === "Published" ? "#31C48D" : "#3F83F8",
                    }}
                  >
                    {row?.status}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton onClick={(e) => handleMenuClick(e, row)}>
                      <MoreVert />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Box>
      {!loading && permissionCount === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        TransitionProps={{
          exit: false,
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            openModal("edit_permission");
          }}
          disabled={menuLoader}
        >
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={publishPermission}
          disabled={menuLoader || selectedRow?.status === "Published"}
        >
          <ListItemText>Publish</ListItemText>
          {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>

      {/* {showFilterModal && (
        <FeatureFilterModal
          showModal={showFilterModal}
          closeModal={closeFilterModal}
        />
      )} */}

      {showModal === "edit_permission" && (
        <EditPermissionModal
          showModal={showModal === "edit_permission"}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default Study;
