import { Box } from "@mui/material";
import React, { useState } from "react";

import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import Study from "./Tabs/Study";
import Site from "./Tabs/Site";

type Props = {
  type: string;
  showModal: string;
  setShowModal: Function;
  searchText: string;
  setSearchText: Function;
};

const Permissions = ({
  type,
  showModal,
  searchText,
  setShowModal,
  setSearchText,
}: Props) => {
  const [childTab, setChildTab] = useState("study");

  const handleChange = (_: any, newValue: string) => {
    setChildTab(newValue);
  };
  return (
    <Box>
      <Box
        sx={{
          borderTop: 1,
          borderColor: "#E5E7EB",
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        <StyledTabs value={childTab} onChange={handleChange}>
          <StyledTab label="Program" value="study" {...a11yProps(0)} />
          <StyledTab label="Center" value="site" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box>
        <MainTabPanel value={childTab} index={"study"}>
          <Study
            type={type}
            childTab={childTab}
            showModal={showModal}
            setShowModal={setShowModal}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </MainTabPanel>
        <MainTabPanel value={childTab} index={"site"}>
          <Site
            type={type}
            childTab={childTab}
            showModal={showModal}
            setShowModal={setShowModal}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </MainTabPanel>
      </Box>
    </Box>
  );
};

export default Permissions;
