import { fieldMap } from "../Components/QuestionBuilder/questionTypes";
import { v4 as uuid } from "uuid";
import { translateFieldMap } from "../Components/QuestionBuilder/Translation/translateQuestionTypes";

export const repeatedDataTypes: any = {
  adverse_event: "Adverse Event",
  medication: "Medication",
  repeated_measure: "Repeated Measure",
  event: "Event",
  unscheduled_visit: "Unscheduled Visit",
  other: "Other",
};

export const createQuestionSkeleton = (type: string) => ({
  ...fieldMap(type),
  type,
  id: uuid(),
});

export const translateQuestionSkeleton = (type: string) => ({
  ...translateFieldMap(type),
  type,
});

export const createNewOption = () => ({
  ref: uuid(),
  label: "",
  value: "",
  isOther: false,
});

export const prefillOptionFormatter = (options: any[]): any[] => {
  return options.map((opt) => {
    return {
      ref: uuid(),
      label: opt.label,
      value: opt.value,
      isOther: false,
    };
  });
};

export const createNewRepeatedColumn = () => ({
  label: "",
  type: "text",
});

export const createNewGrid = () => ({
  label: "",
  type: "text",
});

const baseValidation = {
  type: "info",
  message: "",
};

export const createNewValidation = () => {
  return baseValidation;
};

const baseDependency = {
  parentFormId: "",
  parentQuestionId: "",
  operator: "",
};

export const createNewDependency = (formCategory: string, formId: string) => {
  let item = baseDependency;

  if (formCategory !== "visit") {
    item = { ...item, parentFormId: formId };
  }

  return item;
};

export const extractVariablesFromString = (value: string) => {
  const regex = /\{[A-Za-z][A-Za-z0-9._-]*\}/g;
  const matches = value.match(regex);

  if (matches) {
    const uniqueMatches = new Set(matches.map((match) => match.slice(1, -1)));

    return Array.from(uniqueMatches).map((varname) => ({
      varname,
      type: "string",
      value: "",
    }));
  }

  return [];
};

export const calculatePercentage = (data: any) => {
  const completed = data?.data?.progress?.completed || 0;
  const total = data?.data?.progress?.total || 0;

  return total > 0 ? (completed / total) * 100 : 0;
};
