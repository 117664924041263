import { Button, Stack, TextField } from "@mui/material";
import { QuestionSlice } from "../../../Redux/reducers/responseSlice";
import ValidationField from "../ValidationField";
import { useAppDispatch } from "../../../Redux/hooks";
import { handleManualCalculation } from "../../../Redux/actions/responseEventAction";
import { useParams } from "react-router-dom";

type Props = {
  currentQuestion: QuestionSlice;
};

const CalculatedField = ({ currentQuestion }: Props) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();
  const { responses } = currentQuestion;
  let currentResponse =
    currentQuestion.properties?.responseType === "string"
      ? responses?.[0]?.textValue ?? ""
      : typeof responses?.[0]?.numberValue === "number"
      ? responses?.[0]?.numberValue
      : "";

  const buttonHandler = () => {
    dispatch(
      handleManualCalculation({
        id: currentQuestion.id,
        studyId,
        surveySlug,
      })
    );
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md: "80%",
        },
        pl: "60px",
      }}
      gap={1}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <TextField
          name="textValue"
          fullWidth
          placeholder="Calculated Value"
          value={currentResponse}
          InputProps={{ readOnly: true }}
        />
        {currentQuestion.properties.executionType === "manual" && (
          <Button onClick={buttonHandler} variant="contained" sx={{ ml: 2 }}>
            Calculate
          </Button>
        )}
      </Stack>

      {responses?.[0]?.questionValidation && (
        <ValidationField
          validationObject={responses?.[0]?.questionValidation}
        />
      )}
    </Stack>
  );
};
export default CalculatedField;
