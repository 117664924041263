import { useEffect } from "react";
import { useAppDispatch } from "../../../Redux/hooks";
import { calculationCallback } from "../../../Redux/actions/responseAction";

const CalculationWrapper = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const iframeFn = async () => {
      const iframeContainer = document.getElementById("calc-iframe-container");
      if (iframeContainer) {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("sandbox", "allow-scripts");
        iframe.style.display = "none";
        iframe.src = "/iframe.html";
        iframeContainer.appendChild(iframe);
      }
    };
    iframeFn();
    return () => {
      const iframeContainer = document.getElementById("calc-iframe-container");
      if (iframeContainer) {
        while (iframeContainer.lastChild) {
          iframeContainer.removeChild(iframeContainer.lastChild);
        }
      }
    };
  }, []);

  useEffect(() => {
    const callback = (event: any) => {
      if (event?.data?.type === "calculation_result") {
        const args = event?.data?.arguments;
        dispatch(
          calculationCallback({
            result: event.data.result,
            questionId: args?.questionId,
            studyId: args?.studyId,
            surveySlug: args?.surveySlug,
            phaseId: args?.phaseId,
            formId: args?.formId,
          })
        );
      }
    };
    window.addEventListener("message", callback);
    return () => {
      window.removeEventListener("message", callback);
    };
  }, [dispatch]);

  return <div id="calc-iframe-container"></div>;
};

export default CalculationWrapper;
