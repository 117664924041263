import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

interface AnalysisState {
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
  toggleReload: boolean;
  dateLoaded: boolean;
  user: any;
  criteria: string;
  resolution: string;
  offSet: number;
  disableButton: boolean;
  selectedGraphs: string[];
  dateRange: number;
  disabledResolutions: string[];
  graphShouldLoad: boolean;
  dataType: "raw" | "aggregated";
}

const initialState: AnalysisState = {
  startDate: null,
  endDate: null,
  loading: true,
  toggleReload: false,
  dateLoaded: false,
  user: null,
  criteria: "day",
  resolution: "1Day",
  offSet: 0,
  disableButton: false,
  selectedGraphs: [],
  dateRange: 7,
  disabledResolutions: [],
  graphShouldLoad: true,
  dataType: "raw",
};

const getIntialState = (): AnalysisState => {
  return {
    ...initialState,
  };
};

export const analysisSlice = createSlice({
  name: "analysis",
  initialState: getIntialState,
  reducers: {
    setHealthStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
      if (action.payload && state.endDate) {
        let now = DateTime.now();
        if (state.dataType === "raw") {
          const start = DateTime.fromFormat(
            action.payload,
            "LL-dd-yyyy HH:mm:ss"
          );
          const end = DateTime.fromFormat(state.endDate, "LL-dd-yyyy HH:mm:ss");
          const inRange = now >= start && now.plus({ minutes: -1 }) <= end;
          state.graphShouldLoad = inRange;
        } else if (state.dataType === "aggregated") {
          const start = DateTime.fromFormat(
            action.payload,
            "LL-dd-yyyy"
          ).startOf("day");
          const end = DateTime.fromFormat(state.endDate, "LL-dd-yyyy").endOf(
            "day"
          );
          const inRange = now >= start && now <= end;

          state.graphShouldLoad = inRange;
        }
      } else {
        state.graphShouldLoad = false;
      }
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setHealthEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
      if (action.payload && state.startDate) {
        let now = DateTime.now();
        if (state.dataType === "raw") {
          const start = DateTime.fromFormat(
            state.startDate,
            "LL-dd-yyyy HH:mm:ss"
          );
          const end = DateTime.fromFormat(
            action.payload,
            "LL-dd-yyyy HH:mm:ss"
          );
          const inRange = now >= start && now.plus({ minutes: -1 }) <= end;
          state.graphShouldLoad = inRange;
        } else if (state.dataType === "aggregated") {
          const start = DateTime.fromFormat(
            state.startDate,
            "LL-dd-yyyy"
          ).startOf("day");
          const end = DateTime.fromFormat(action.payload, "LL-dd-yyyy").endOf(
            "day"
          );
          const inRange = now >= start && now <= end;
          state.graphShouldLoad = inRange;
        }
      } else {
        state.graphShouldLoad = false;
      }
    },
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.toggleReload = action.payload;
    },
    setDateLoaded: (state, action: PayloadAction<boolean>) => {
      state.dateLoaded = action.payload;
    },
    setSelectedCriteria: (state, action: PayloadAction<string>) => {
      state.criteria = action.payload;
    },
    setHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonToggle: (state, action: PayloadAction<boolean>) => {
      state.disableButton = action.payload;
    },
    setResolution: (state, action: PayloadAction<string>) => {
      state.resolution = action.payload;
    },
    setSelectedGraphs: (state, action: PayloadAction<string[]>) => {
      state.selectedGraphs = action.payload;
    },
    clearSelectedGraphs: (state) => {
      state.selectedGraphs = [];
    },
    setOffSet: (state, action: PayloadAction<number>) => {
      state.offSet = action.payload;
    },
    setDateRange: (state, action: PayloadAction<number>) => {
      state.dateRange = action.payload;
    },
    setDisabledResolutions: (state, action: PayloadAction<string[]>) => {
      state.disabledResolutions = action.payload;
    },
    setGraphShouldLoad: (state, action: PayloadAction<boolean>) => {
      state.graphShouldLoad = action.payload;
    },
    changeDataType: (state, action: PayloadAction<"raw" | "aggregated">) => {
      state.dataType = action.payload;
    },
    resetDateTime: (state) => {
      state.startDate = null;
      state.endDate = null;
      state.dateLoaded = false;
      state.toggleReload = false;
    },
  },
});

export const {
  setHealthStartDate,
  setHealthEndDate,
  setToggle,
  setDateLoaded,
  setHealthLoading,
  setUserDetails,
  setOffSet,
  setButtonToggle,
  setSelectedCriteria,
  setSelectedGraphs,
  clearSelectedGraphs,
  setDateRange,
  setResolution,
  setGraphShouldLoad,
  setDisabledResolutions,
  changeDataType,
  resetDateTime,
} = analysisSlice.actions;

export default analysisSlice.reducer;
