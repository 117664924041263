import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Box,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  CreateFormBox,
  CreateFormInputRow,
  UploadWrapper,
} from "./Organisation";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { toastMessage, errorToastMessage } from "../../utils/toast";
import { organisationUploadUrl } from "../../utils/upload";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { useDropzone } from "react-dropzone";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { UploadIcon } from "../Common/assets/Icons";
import LogoutContainer from "../Layout/LogoutContainer";
import { HeaderStyle } from "../Common/styles/header";
import { ModalActionButtonStyles } from "../Common/styles/modal";
import { ChevronLeft } from "@mui/icons-material";
import isEmail from "validator/es/lib/isEmail";

const validationSchema = yup.object().shape({
  organisationName: yup.string().required("Organisation Name is required"),
  description: yup.string().optional(),
  type: yup.string().required("Type is required"),
  // parentOrganisation: yup
  //   .string()
  //   .required("Parent Organisation Name is required"),
  primaryContact: yup.string().required("Primary Contact is required"),
  phoneNumber: yup.number().optional(),
  email: yup
    .string()
    .required("*Email ID is Required")
    .test("is-valid", "*Please enter a valid Email ID.", (value) =>
      value ? isEmail(value) : false
    ),
  status: yup.string().required("Status is required"),
  logoUrl: yup.object().shape({
    url: yup.string().optional(),
    previewUrl: yup.string().optional(),
  }),
});

const organisationType = [
  { label: "Individual Organisation", value: "individual" },
  { label: "White Label Organisation", value: "white_label" },
];
// const ParentOrganisations = [
//   { label: "Parent Org 1", value: "parentOrg1" },
//   { label: "Parent Org 2", value: "parentOrg2" },
//   { label: "Parent Org 3", value: "parentOrg3" },
// ];
const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" },
  { label: "Archived", value: "archived" },
  // { label: "Deleted", value: "deleted" },
];

const UploadItem: React.FC<any> = ({ image, setFieldValue, orgnizationId }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const obj = await organisationUploadUrl(file, orgnizationId);
          if (obj.postUploadImageUrl) {
            setFieldValue("logoUrl.url", obj.postUploadImageUrl);
          }
          if (obj.previewUrl) {
            setFieldValue("logoUrl.previewUrl", obj.previewUrl);
          }

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue, orgnizationId]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Box sx={UploadWrapper}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <UploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Click to upload or drag and drop
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const CreateOrganisation = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState<any>({});

  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(`/organizations/${id}`);
        const response = res?.data?.data;
        setData(response);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (id !== "new") {
      fetchDetails();
    } else {
      setData({});
      setLoading(false);
    }
  }, [id, setData, setLoading]);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        name: values?.organisationName,
        description: values?.description,
        type: values?.type,
        status: values?.status,
        mainContactName: values?.primaryContact,
        mainContactEmail: values?.email,
        mainContactPhoneNumber: values?.phoneNumber,
        logoUrl: values?.logoUrl?.url,
      };
      let res: AxiosResponse;
      if (id !== "new") {
        res = await http.patch(`/organizations/${id}`, body);
      } else {
        res = await http.post(`/organizations`, body);
      }
      toastMessage("success", res.data.message);
      navigate(`/app/organisation/view/${res?.data?.data?.id}`);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <Box sx={{ ml: "auto" }}>
          <LogoutContainer />
        </Box>
      </Box>
      <Box sx={HeaderStyle}>
        <IconButton onClick={handleBack}>
          <ChevronLeft sx={{ cursor: "pointer" }} fontSize="large" />
        </IconButton>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          {id !== "new" ? "Edit Organisation" : "Create Organisation"}
        </Typography>
      </Box>
      {!loading ? (
        <Box sx={{ p: 3, height: "calc(100vh - 144px)", overflow: "auto" }}>
          <Formik
            initialValues={{
              organisationName: data?.name || "",
              type: data?.type || "individual",
              description: data?.description || "",
              // parentOrganisation: data?.parentOrganisation || "",
              primaryContact: data?.mainContactName || "",
              phoneNumber: data?.mainContactPhoneNumber || "",
              email: data?.mainContactEmail || "",
              status: data?.status || "draft",
              // logoUrl: data?.signedLogoUrl || "",
              logoUrl: {
                previewUrl: data?.signedLogoUrl || "",
                url: data?.logoUrl || "",
              },
            }}
            onSubmit={(values) => {
              submitHandler(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              setFieldValue,
              getFieldProps,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={CreateFormBox}>
                  <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="organisation-name">
                        Organisation Name{" "}
                        <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <TextField
                        placeholder="Organisation Name"
                        id="organisation-name"
                        {...getFieldProps("organisationName")}
                        error={
                          touched?.organisationName && errors?.organisationName
                            ? true
                            : false
                        }
                        helperText={
                          touched?.organisationName && errors?.organisationName
                            ? (errors?.organisationName as string)
                            : " "
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="description">
                        Description
                      </FormLabel>
                      <TextField
                        multiline
                        placeholder={`Description`}
                        minRows={4}
                        id="description"
                        {...getFieldProps("description")}
                        error={
                          touched?.description && errors?.description
                            ? true
                            : false
                        }
                        helperText={
                          touched?.description && errors?.description
                            ? (errors?.description as string)
                            : " "
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="type">
                        Type <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <Select
                        id="type"
                        fullWidth
                        value={values?.type}
                        onChange={(e) => setFieldValue("type", e.target.value)}
                      >
                        {organisationType.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={touched?.type && errors?.type ? true : false}
                      >
                        {touched?.type && errors?.type
                          ? (errors?.type as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {/* <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="parent-organisation">
                        Parent Organisation{" "}
                        <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <Select
                        id="parent-organisation"
                        fullWidth
                        value={values?.parentOrganisation}
                        onChange={(e) =>
                          setFieldValue("parentOrganisation", e.target.value)
                        }
                      >
                        {ParentOrganisations.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={
                          touched?.parentOrganisation &&
                          errors?.parentOrganisation
                            ? true
                            : false
                        }
                      >
                        {touched?.parentOrganisation &&
                        errors?.parentOrganisation
                          ? (errors?.parentOrganisation as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </Box> */}
                  <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="primary-contact">
                        Primary Contact{" "}
                        <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <TextField
                        placeholder="Primary Contact"
                        id="primary-contact"
                        {...getFieldProps("primaryContact")}
                        error={
                          touched?.primaryContact && errors?.primaryContact
                            ? true
                            : false
                        }
                        helperText={
                          touched?.primaryContact && errors?.primaryContact
                            ? (errors?.primaryContact as string)
                            : " "
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ ...CreateFormInputRow, gap: 2 }}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="phone-number">
                        Phone Number
                      </FormLabel>
                      <TextField
                        placeholder="Phone Number"
                        id="phone-number"
                        {...getFieldProps("phoneNumber")}
                        error={
                          touched?.phoneNumber && errors?.phoneNumber
                            ? true
                            : false
                        }
                        helperText={
                          touched?.phoneNumber && errors?.phoneNumber
                            ? (errors?.phoneNumber as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="email">
                        Email <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <TextField
                        placeholder="Email"
                        id="email"
                        {...getFieldProps("email")}
                        error={touched?.email && errors?.email ? true : false}
                        helperText={
                          touched?.email && errors?.email
                            ? (errors?.email as string)
                            : " "
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={CreateFormInputRow}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="status">
                        Status
                      </FormLabel>
                      <Select
                        value={values.status}
                        onChange={(e) => {
                          setFieldValue("status", e.target.value);
                        }}
                        fullWidth
                        id="status"
                        displayEmpty
                      >
                        {statusOptions.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={touched?.status && errors?.status ? true : false}
                      >
                        {touched?.status && errors?.status
                          ? (errors?.status as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {id !== "new" && (
                    <Box
                      sx={{
                        ...CreateFormInputRow,
                        "& .preview-image": {
                          height: "196px",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <FormControl sx={InputWrapper}>
                        <FormLabel
                          sx={LabelStyle}
                          htmlFor="organisation-logo-upload"
                        >
                          Logo
                        </FormLabel>
                        <UploadItem
                          image={values?.logoUrl?.previewUrl}
                          setFieldValue={setFieldValue}
                          orgnizationId={id}
                        />
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={ModalActionButtonStyles}>
                    {!submitLoader ? (
                      <>
                        <Button variant="outlined" onClick={handleBack}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </>
                    ) : (
                      <CircularProgress size={25} />
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <CircularProgress size={25} />
        </Box>
      )}
    </Box>
  );
};

export default CreateOrganisation;
