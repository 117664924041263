import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Logo, DashboardIcon } from "../Common/assets/Sidebar";
import VersionWrapper from "../Common/UI/VersionWrapper";
// import { setCollapsed } from "../../Redux/reducers/appSlice";

const Sidebar = () => {
  return (
    <Box
      className={`sidebar`}
      sx={{
        "& .sider-menu": {
          color: "text.secondary",
        },
        "& .active.sider-menu": {
          backgroundColor: "secondary.main",
        },
        "& .user-management-menu": {
          color: "text.secondary",
        },
        "& .active.user-management-menu": {
          color: (theme) => theme.palette.primary.main,
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            margin: "10px 0px",
          }}
        >
          <Logo />
        </Box>
        <NavLink
          to="/app/studies"
          title="My Programs"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          <Typography variant="subtitle1">My Programs</Typography>
        </NavLink>

        {/* <NavLink
          to="/app/organisation"
          title="Organisation"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          {!collapsed && (
            <Typography variant="subtitle1">Organisation</Typography>
          )}
        </NavLink>
        <NavLink
          to="/app/features"
          title="Features"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          {!collapsed && <Typography variant="subtitle1">Features</Typography>}
        </NavLink> */}
        {/* <NavLink
          to="/app/users"
          title="Users"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          {!collapsed && <Typography variant="subtitle1">Users</Typography>}
        </NavLink> */}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton onClick={toggleCollapse}>
          {collapsed ? (
            <ChevronRight fontSize="large" />
          ) : (
            <ChevronLeft fontSize="large" />
          )}
        </IconButton>
      </Box> */}
      <VersionWrapper />
    </Box>
  );
};

export default Sidebar;
