import {
  handleAddRepeatedData,
  handleCheckboxIsOtherUpdate,
  handleCheckboxUpdate,
  handleFieldUpdate,
  handleGridAggregation,
  handleGridUpdate,
  handleRadioUpdate,
  handleRemoveRepeatedData,
  handleRepeatedColumnAggregation,
  handleRepeatedDataAggregation,
  handleRepeatedUpdate,
  setCalcFields,
  setResponseSubmitting,
} from "../reducers/responseSlice";
import { AppThunk } from "../store";
import { handleCalculation, updateResponse } from "./responseAction";
import isEmail from "validator/es/lib/isEmail";

type textChangeProps = {
  id: string;
  value: string;
  format: string;
};
export const handleTextChange =
  ({ id, value, format }: textChangeProps): AppThunk<string | null> =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleFieldUpdate({
          key: "textValue",
          convertToInt: false,
          value: value,
          id: id,
        })
      );
      if (format === "email" || format === "alphabets") return "";
      else return null;
    }
    return null;
  };

type textBlurProps = {
  id: string;
  value: string;
  format: string;
  studyId?: string;
  surveySlug?: string;
};
export const handleTextBlur =
  ({
    id,
    value,
    format,
    studyId,
    surveySlug,
  }: textBlurProps): AppThunk<string | null> =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (id && formEditable && !fieldSubmitting) {
      if (format === "email") {
        const isEmailValid = value ? isEmail(value) : true;
        if (isEmailValid) {
          dispatch(
            updateResponse({
              studyId,
              questionId: id,
              surveySlug,
            })
          );
          return "";
        } else {
          return "Please enter a valid email";
        }
      } else if (format === "alphabets") {
        let isValid: any = true;
        if (value) {
          const regex = /^[a-zA-Z]*$/;
          isValid = value.match(regex);
        }
        if (isValid) {
          dispatch(
            updateResponse({
              studyId,
              questionId: id,
              surveySlug,
            })
          );
          return "";
        } else {
          return "Please enter only alphabets(a-z, A-Z)";
        }
      } else {
        dispatch(
          updateResponse({
            studyId,
            questionId: id,
            surveySlug,
          })
        );
        return null;
      }
    }
    return null;
  };

const validator = (value: string, properties: any): string => {
  const valueAsNumber = parseFloat(value);

  if (!properties?.allowDecimal && value.toString().includes(".")) {
    return "Value must not be a decimal";
  } else if (
    typeof properties?.min === "number" &&
    typeof properties?.max === "number" &&
    (valueAsNumber < properties.min || valueAsNumber > properties.max)
  ) {
    return `Value must be between ${properties?.min} and ${properties?.max}`;
  } else if (
    typeof properties?.min === "number" &&
    valueAsNumber < properties.min
  ) {
    return `Value must be greater than ${properties?.min}`;
  } else if (
    typeof properties?.max === "number" &&
    valueAsNumber > properties.max
  ) {
    return `Value must be less than ${properties?.max}`;
  } else {
    return "";
  }
};
type numberChangeProps = {
  id: string;
  value: string;
  properties: any;
};
export const handleNumberChange =
  ({ id, value, properties }: numberChangeProps): AppThunk<string | null> =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      const errString = validator(value, properties);
      dispatch(
        handleFieldUpdate({
          key: "numberValue",
          convertToInt: true,
          value: value,
          id: id,
        })
      );
      return errString;
    }
    return null;
  };

type sliderChangeProps = {
  id: string;
  value: number | number[];
};
export const handleSliderChange =
  ({ id, value }: sliderChangeProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleFieldUpdate({
          key: "numberValue",
          convertToInt: true,
          value: value.toString(),
          id: id,
        })
      );
    }
  };

type manualCalculationProps = {
  id: string;
  studyId?: string;
  surveySlug?: string;
};

export const handleManualCalculation =
  ({ id, studyId, surveySlug }: manualCalculationProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable, calcFields } = getState().response;
    if (!fieldSubmitting && formEditable && calcFields.length === 0) {
      dispatch(setResponseSubmitting(id));
      dispatch(setCalcFields({ f: [id] }));
      dispatch(handleCalculation(studyId, surveySlug));
    }
  };

type dateChangeProps = {
  id: string;
  value: string;
  studyId?: string;
  surveySlug?: string;
};
export const handleDateChange =
  ({ id, value, studyId, surveySlug }: dateChangeProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleFieldUpdate({
          key: "textValue",
          convertToInt: false,
          value: value,
          id: id,
        })
      );

      if (id) {
        dispatch(
          updateResponse({
            studyId,
            questionId: id,
            surveySlug,
          })
        );
      }
    }
  };

type radioChange = {
  id: string;
  choiceId: string;
  textValue?: string;
  studyId?: string;
  surveySlug?: string;
};
export const handleRadioChange =
  ({ id, choiceId, textValue, studyId, surveySlug }: radioChange): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleRadioUpdate({
          choiceId,
          textValue,
          id: id,
        })
      );

      if (id && typeof textValue === "undefined") {
        dispatch(
          updateResponse({
            studyId,
            questionId: id,
            surveySlug,
          })
        );
      }
    }
  };

type checkboxChange = {
  studyId?: string;
  surveySlug?: string;
  id: string;
  choiceId: string;
  textValue?: string;
};
export const handleCheckboxChange =
  ({
    studyId,
    surveySlug,
    id,
    choiceId,
    textValue,
  }: checkboxChange): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleCheckboxUpdate({
          choiceId,
          textValue,
          id: id,
        })
      );

      if (id && typeof textValue === "undefined") {
        dispatch(
          updateResponse({
            studyId,
            questionId: id,
            surveySlug,
          })
        );
      }
    }
  };

type checkboxOtherChange = {
  id: string;
  choiceId: string;
  textValue: string;
};
export const handleCheckboxOtherChange =
  ({ id, choiceId, textValue }: checkboxOtherChange): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleCheckboxIsOtherUpdate({
          choiceId,
          textValue,
          id: id,
        })
      );
    }
  };

type gridSaveProps = {
  value: any;
  rowIndex: number;
  colIndex: number;
  key: string;
  id: string;
};

export const handleGridChange =
  ({ value, rowIndex, colIndex, key, id }: gridSaveProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleGridUpdate({
          key,
          convertToInt: key === "numberValue",
          value: value,
          id: id,
          rowIndex,
          colIndex,
        })
      );
    }
  };

type gridAggregateProps = {
  rowIndex: number;
  colIndex: number;
  rowAggregation: string;
  columnAggregation: string;
  id: string;
};

export const triggerGridAggregate =
  ({
    rowIndex,
    colIndex,
    columnAggregation,
    rowAggregation,
    id,
  }: gridAggregateProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleGridAggregation({
          id: id,
          rowIndex,
          colIndex,
          rowAggregation,
          columnAggregation,
        })
      );
    }
  };

type rpAddProps = {
  id: string;
};

export const handleRpAdd =
  ({ id }: rpAddProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (id && !fieldSubmitting && formEditable) {
      dispatch(handleAddRepeatedData({ id }));
    }
  };

type rpDeleteProps = {
  id: string;
  index: number;
  columnAggregate: boolean;
};

export const handleRpDelete =
  ({ id, index, columnAggregate }: rpDeleteProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (id && !fieldSubmitting && formEditable) {
      dispatch(
        handleRemoveRepeatedData({
          id,
          index,
        })
      );
      if (columnAggregate) {
        dispatch(
          handleRepeatedColumnAggregation({
            id: id,
          })
        );
      }
    }
  };

type rpChangeProps = {
  value: any;
  rowIndex: number;
  colIndex: number;
  key: string;
  id: string;
};

export const handleRpChange =
  ({ value, rowIndex, colIndex, key, id }: rpChangeProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleRepeatedUpdate({
          key,
          convertToInt: key === "numberValue",
          value: value,
          id: id,
          rowIndex,
          colIndex,
        })
      );
    }
  };

type rpAggregateProps = {
  rowIndex: number;
  colIndex: number;
  rowAggregation: string;
  columnAggregation: string;
  id: string;
};

export const triggerRpAggregate =
  ({
    rowIndex,
    colIndex,
    columnAggregation,
    rowAggregation,
    id,
  }: rpAggregateProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (!fieldSubmitting && formEditable) {
      dispatch(
        handleRepeatedDataAggregation({
          id: id,
          rowIndex,
          colIndex,
          rowAggregation,
          columnAggregation,
        })
      );
    }
  };

type triggerSaveProps = {
  id: string;
  studyId?: string;
  surveySlug?: string;
};
export const triggerSave =
  ({ id, studyId, surveySlug }: triggerSaveProps): AppThunk =>
  (dispatch, getState) => {
    const { fieldSubmitting, formEditable } = getState().response;
    if (id && !fieldSubmitting && formEditable) {
      dispatch(
        updateResponse({
          studyId,
          questionId: id,
          surveySlug,
        })
      );
    }
  };
