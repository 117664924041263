const basicField = {
  label: "",
  helperText: "",
};

const textField = {
  ...basicField,
  properties: {
    remarkText: "",
    displayNumber: "",
  },
};

const numberField = {
  ...basicField,
  properties: {
    measurementUnit: "",
    remarkText: "",
    displayNumber: "",
  },
};

const sliderField = {
  ...basicField,
  properties: {
    minLabel: "",
    maxLabel: "",
    remarkText: "",
    displayNumber: "",
  },
};

const statementField = {
  ...basicField,
  properties: {},
};

// const gridField = {
//   ...basicField,
//   properties: {
//     remarkText: "",
//     gridConfig: {
//       fieldType: "row",
//       rows: [{ label: "", type: "text" }],
//       columns: [{ label: "" }],
//     },
//   },
// };

// const repeatedField = {
//   ...basicField,
//   properties: {
//     remarkText: "",
//     repeatedConfig: {
//       columns: [
//         {
//           label: "",
//           type: "text",
//         },
//       ],
//     },
//   },
// };

export const translateFieldMap = (type: string) => {
  switch (type) {
    case "slider":
      return sliderField;
    case "number":
      return numberField;
    case "radio":
    case "checkbox":
    case "dropdown":
    case "text":
    case "date":
    case "calculated_field":
    case "upload_file":
      return textField;
    case "statement":
    case "summary":
    case "randomize":
    case "eligibility":
    case "repeated_measure":
      return statementField;
    case "grid":
      return textField;
    case "repeated_data":
      return textField;

    default:
      return {};
  }
};
