import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { filterBox, SearchBox } from "../../Organisations/Organisation";
import { NoDataContainer, StyledTableCell } from "../../Common/styles/table";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import {
  AddRounded,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  ExpandMoreRounded,
  MoreVert,
  SortRounded,
} from "@mui/icons-material";
import AddFeatureModal from "../AddFeatureModal";
import FeatureFilterModal from "../FeatureFilterModal";
import { fetchFeaturesList } from "../../../Redux/actions/featuresAction";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setFeaturesDetails,
  // setFeaturesToggle,
  setSearchText,
} from "../../../Redux/reducers/featuresSlice";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import AddSubFeatureModal from "../AddSubFeatureModal";

type Props = {
  type: string;
  showModal: string;
  setShowModal: Function;
};
const statusMap: any = {
  draft: { label: "Draft", color: "#3F83F8" },
  active_new: { label: "Active New", color: "#31C48D" },
  active_beta: { label: "Active Beta", color: "#31C48D" },
  active_regular: { label: "Active Regular", color: "#31C48D" },
  hold: { label: "Hold", color: "#F05252" },
  deprecated: { label: "Deprecated", color: "text.primary" },
};
const categoryMap: any = {
  functional: "Functional",
  page: "Page",
  device_type: "Device Type",
  menu: "Menu",
  api: "API",
  entity: "Entity",
};

const FeaturesList = ({ type, showModal, setShowModal }: Props) => {
  const dispatch = useAppDispatch();

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [triggerEvent, setTriggerEvent] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const {
    totalFeatures,
    featuresData,
    tag,
    category,
    status,
    searchText,
    sortOrder,
    sortColumn,
    loading,
    // toggleLoader,
  } = useAppSelector((state) => state.features);

  useEffect(() => {
    dispatch(
      fetchFeaturesList(
        sortOrder,
        sortColumn,
        category,
        tag,
        status,
        searchText
      )
    );
  }, [
    dispatch,
    searchText,
    status,
    toggleLoader,
    sortOrder,
    sortColumn,
    tag,
    category,
  ]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    row: any,
    name: string
  ) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
    setTriggerEvent(name);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setSearchText(val));
      }, 500),
    [dispatch]
  );
  const openFilterModal = () => {
    setShowFilterModal(true);
  };
  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const openModal = (type: string) => {
    setShowModal(type);
  };

  const closeModal = () => {
    setShowModal("");
    setSelectedRow(null);
  };

  const deleteFeature = async () => {
    try {
      setMenuLoader(true);
      const res = await http.delete(`features/${selectedRow?.id}`);
      handleMenuClose();
      toastMessage("success", res.data.message);
      setMenuLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };
  const handleExpandToggle = (id: string) => {
    const updatedFeaturesData = featuresData.map((feature: any) =>
      feature.id === id ? { ...feature, expanded: !feature.expanded } : feature
    );

    dispatch(setFeaturesDetails(updatedFeaturesData));
  };

  return (
    <Box>
      <Box>
        <Box sx={SearchBox}>
          <TextField
            sx={{ width: "70%" }}
            variant="outlined"
            placeholder="Search"
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#637E85" }} />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ ...filterBox, width: "30%" }} onClick={openFilterModal}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <SortRounded />
              <Typography>Filter</Typography>
            </Box>
            <ExpandMoreRounded />
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Feature Name</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Tags</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>

          {!loading && totalFeatures > 0 && (
            <TableBody>
              {featuresData.map((row: any) => (
                <React.Fragment key={row?.id}>
                  <TableRow key={row.id}>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton onClick={() => handleExpandToggle(row?.id)}>
                          {row?.expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Typography fontSize={16} fontWeight={400}>
                          {row.featureName}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {categoryMap?.[row.category]}
                    </StyledTableCell>
                    <StyledTableCell>{row.tagString}</StyledTableCell>
                    <StyledTableCell
                    // sx={{
                    //   color: statusMap?.[row.status]?.color,
                    // }}
                    >
                      {statusMap?.[row.status]?.label}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <IconButton
                        onClick={(e) => handleMenuClick(e, row, "feature")}
                      >
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                  {row?.expanded &&
                    row?.childrenFeatures?.map((subItem: any) => (
                      <TableRow key={subItem.id}>
                        <StyledTableCell>
                          <Typography
                            fontSize={16}
                            fontWeight={400}
                            sx={{ ml: 8 }}
                          >
                            {subItem?.featureName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {categoryMap?.[subItem.category]}
                        </StyledTableCell>
                        <StyledTableCell>{subItem.tagString}</StyledTableCell>
                        <StyledTableCell
                        // sx={{
                        //   color: statusMap?.[subItem.status]?.color,
                        // }}
                        >
                          {statusMap?.[subItem.status]?.label}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            onClick={(e) =>
                              handleMenuClick(e, subItem, "sub_feature")
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </Box>
      {!loading && totalFeatures === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            openModal(triggerEvent);
          }}
          disabled={menuLoader}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={deleteFeature} disabled={menuLoader}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
        {triggerEvent === "feature" && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              openModal("sub_feature");
            }}
            disabled={menuLoader}
          >
            <ListItemIcon>
              <AddRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Sub Feature</ListItemText>
          </MenuItem>
        )}
      </Menu>

      {showFilterModal && (
        <FeatureFilterModal
          showModal={showFilterModal}
          closeModal={closeFilterModal}
        />
      )}
      {/* {showModal && (
        <AddFeatureModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )} */}
      {showModal === "feature" && (
        <AddFeatureModal
          showModal={showModal === "feature"}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )}

      {showModal === "sub_feature" && (
        <AddSubFeatureModal
          showModal={showModal === "sub_feature"}
          closeModal={closeModal}
          parentDataId={triggerEvent === "feature" ? selectedRow?.id : ""}
          data={triggerEvent === "sub_feature" ? selectedRow : null}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default FeaturesList;
