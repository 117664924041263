import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AuthLayout, SignInWrapper } from "./auth.style";
import { StyledButton } from "../Common/styles/button";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import isEmail from "validator/es/lib/isEmail";
import { Logo } from "../Common/assets/Sidebar";
import { useParams } from "react-router-dom";
import SurveyLinkSent from "./SurveyLinkSent";

const schema = yup.object({
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
  email: yup
    .string()
    .required("*Email ID is Required")
    .test("is-valid", "*Please enter a valid Email ID.", (value) =>
      value ? isEmail(value) : false
    ),
});

const UserRegistration = () => {
  const { slug } = useParams();

  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [surveySent, setSurveySent] = useState<boolean>(false);

  const handleSubmit = async (data: any) => {
    try {
      setSubmitLoader(true);
      const body: any = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        studySlug: slug,
      };
      let url = "/participant/auth/register";
      const res = await http.post(url, body);
      toastMessage("success", res?.data?.message);
      setSurveySent(true);
      setSubmitLoader(false);
    } catch (err) {
      setSurveySent(false);
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      {surveySent ? (
        <SurveyLinkSent />
      ) : (
        <Box sx={AuthLayout}>
          <Box sx={SignInWrapper}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
              <Logo />
            </Box>
            <Typography
              sx={{ fontSize: "30px", fontWeight: 600, color: "text.primary" }}
              mb={2}
            >
              Create Account
            </Typography>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={schema}
            >
              {({ errors, touched, getFieldProps, isValid, dirty }) => {
                return (
                  <Form>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="first-name">
                        First Name
                      </FormLabel>
                      <TextField
                        placeholder="First Name"
                        id="first-name"
                        {...getFieldProps("firstName")}
                        inputProps={{
                          form: {
                            autocomplete: "given-name",
                          },
                        }}
                        error={
                          touched?.firstName && errors?.firstName ? true : false
                        }
                        helperText={
                          touched?.firstName && errors?.firstName
                            ? (errors?.firstName as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="last-name">
                        Last Name
                      </FormLabel>
                      <TextField
                        inputProps={{
                          form: {
                            autocomplete: "family-name",
                          },
                        }}
                        placeholder="Last Name"
                        id="last-name"
                        {...getFieldProps("lastName")}
                        error={
                          touched?.lastName && errors?.lastName ? true : false
                        }
                        helperText={
                          touched?.lastName && errors?.lastName
                            ? (errors?.lastName as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="email">
                        Your Email <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <TextField
                        placeholder="name@example.com"
                        id="email"
                        {...getFieldProps("email")}
                        error={touched?.email && errors?.email ? true : false}
                        helperText={
                          touched?.email && errors?.email ? errors?.email : " "
                        }
                      />
                    </FormControl>

                    {!submitLoader ? (
                      <StyledButton
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={!(dirty && isValid)}
                        sx={{ mt: 4 }}
                      >
                        Register
                      </StyledButton>
                    ) : (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress size={25} />
                      </Box>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserRegistration;
