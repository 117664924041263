import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { QuestionSlice } from "../../../Redux/reducers/responseSlice";
import { useParams } from "react-router-dom";
import GridDates from "./GridDates";
import Add from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import { Sigh } from "./GridField";
import { useMemo } from "react";
import {
  handleRpAdd,
  handleRpChange,
  handleRpDelete,
  triggerRpAggregate,
  triggerSave,
} from "../../../Redux/actions/responseEventAction";

type Props = {
  currentQuestion: QuestionSlice;
};

const InputField = ({
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
}: Sigh) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.repeatedData?.[rowIndex]?.[colIndex] || "";

  return (
    <TextField
      name="textValue"
      fullWidth
      sx={{ width: 200 }}
      placeholder="Enter Value"
      value={currentResponse?.textValue || ""}
      onChange={(e) =>
        handleChange(e.target.value, rowIndex, colIndex, "textValue")
      }
      InputProps={{ readOnly: isPreview }}
    />
  );
};

const NumberField = ({
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
  rowAggregation,
  columnAggregation,
  handleBlur,
}: Sigh) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.repeatedData?.[rowIndex]?.[colIndex] || "";

  const handleBlurTrigger = () => {
    if (!isPreview && (!!rowAggregation || !!columnAggregation)) {
      handleBlur &&
        handleBlur(rowIndex, colIndex, rowAggregation, columnAggregation);
    }
  };

  return (
    <TextField
      type="number"
      name="numberValue"
      // fullWidth
      sx={{ display: "flex", minWidth: 125, maxWidth: "100%" }}
      placeholder="Enter Numeric Value"
      value={currentResponse.numberValue ?? ""}
      onChange={(e) =>
        handleChange(e.target.value, rowIndex, colIndex, "numberValue")
      }
      onBlur={handleBlurTrigger}
      InputProps={{ readOnly: isPreview }}
    />
  );
};

const SelectField = ({
  choices,
  rowIndex,
  colIndex,
  currentQuestion,
  handleChange,
  isPreview,
}: Sigh & { choices: string[] }) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.repeatedData?.[rowIndex]?.[colIndex] || "";

  return (
    <Select
      fullWidth
      sx={{ width: 200 }}
      id="position"
      value={currentResponse.textValue || ""}
      onChange={(e) => {
        handleChange(e.target.value, rowIndex, colIndex, "textValue");
      }}
      readOnly={isPreview}
    >
      {choices?.map((choice, i) => (
        <MenuItem key={i} value={choice}>
          {choice}
        </MenuItem>
      ))}
    </Select>
  );
};

type AggregateProps = {
  currentQuestion: QuestionSlice;
  index: number;
  type: "row" | "column";
  aggregateType: string;
};
const AggregateField: React.FC<AggregateProps> = ({
  currentQuestion,
  index,
  type,
  aggregateType,
}) => {
  const { responses } = currentQuestion;
  const currentResponse =
    responses?.[0]?.aggregateData?.[type]?.[index]?.numberValue ?? 0;
  return (
    <>
      {aggregateType} : {currentResponse}
    </>
  );
};

const RepeatedField = ({ currentQuestion }: Props) => {
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.formEditable);

  const { properties, responses } = currentQuestion;
  const repeatedConfig = properties?.repeatedConfig.columns;

  const rowAggregate = properties?.repeatedConfig.rowAggregate;
  const columnAggregate = useMemo(() => {
    return repeatedConfig.some((col: any) => !!col.aggregateType);
  }, [repeatedConfig]);

  const currentResArray = responses?.[0]?.repeatedData;

  const handleChange = (
    value: any,
    rowIndex: number,
    colIndex: number,
    key: string
  ) => {
    dispatch(
      handleRpChange({
        key,
        value: value,
        id: currentQuestion.id,
        rowIndex,
        colIndex,
      })
    );
  };

  const triggerAggregate = (
    rowIndex: number,
    colIndex: number,
    rowAggregation: string,
    columnAggregation: string
  ) => {
    dispatch(
      triggerRpAggregate({
        id: currentQuestion.id,
        rowIndex,
        colIndex,
        rowAggregation,
        columnAggregation,
      })
    );
  };

  const handleDelete = (i: number) => {
    dispatch(
      handleRpDelete({
        id: currentQuestion.id,
        index: i,
        columnAggregate: columnAggregate,
      })
    );
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md: "100%",
        },
        pl: "60px",
      }}
      gap={1}
    >
      <TableContainer className="grid-table-container">
        <Table
          aria-label="simple table"
          sx={{
            maxWidth: "100%",
            width: "auto",
          }}
        >
          <TableHead sx={{ bgcolor: "secondary.main" }}>
            <TableRow>
              <TableCell component="th" scope="row">
                Row No
              </TableCell>
              {repeatedConfig?.map((col, i) => (
                <TableCell key={i} component="th" scope="row">
                  {col.label}
                </TableCell>
              ))}
              {rowAggregate && (
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ textTransform: "capitalize" }}
                >
                  {rowAggregate}
                </TableCell>
              )}
              {!isPreview && (
                <TableCell component="th" scope="row">
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& td": {
                padding: "0px",
              },
            }}
          >
            {currentResArray?.map((_: any, i: number) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ minWidth: "85px", textAlign: "center" }}>
                  {i + 1}
                </TableCell>
                {repeatedConfig?.map((col, j) => (
                  <TableCell key={j}>
                    {col.type === "text" ? (
                      <InputField
                        rowIndex={i}
                        colIndex={j}
                        currentQuestion={currentQuestion}
                        handleChange={handleChange}
                        isPreview={isPreview}
                      />
                    ) : col.type === "number" ? (
                      <NumberField
                        rowIndex={i}
                        colIndex={j}
                        currentQuestion={currentQuestion}
                        handleChange={handleChange}
                        isPreview={isPreview}
                        rowAggregation={rowAggregate}
                        columnAggregation={col.aggregateType}
                        handleBlur={triggerAggregate}
                      />
                    ) : col.type === "date" ? (
                      <GridDates
                        rowIndex={i}
                        colIndex={j}
                        dateFormat={col?.format}
                        currentQuestion={currentQuestion}
                        handleChange={handleChange}
                        isGrid={false}
                        isPreview={isPreview}
                      />
                    ) : (
                      <SelectField
                        rowIndex={i}
                        colIndex={j}
                        currentQuestion={currentQuestion}
                        handleChange={handleChange}
                        choices={col.options || []}
                        isPreview={isPreview}
                      />
                    )}
                  </TableCell>
                ))}
                {rowAggregate && (
                  <TableCell
                    sx={{ minWidth: "85px", textTransform: "capitalize" }}
                  >
                    <AggregateField
                      currentQuestion={currentQuestion}
                      index={i}
                      type={"row"}
                      aggregateType={rowAggregate}
                    />
                  </TableCell>
                )}

                {!isPreview && (
                  <TableCell sx={{ display: "flex", gap: "10px", mt: "8px" }}>
                    <IconButton
                      onClick={() => {
                        dispatch(handleRpAdd({ id: currentQuestion.id }));
                      }}
                    >
                      <Add color="primary" />
                    </IconButton>
                    {i !== 0 && (
                      <IconButton
                        onClick={() => {
                          handleDelete(i);
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}

            {columnAggregate && (
              <TableRow
                key={"col-aggregate"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component={"th"} scope={"row"}>
                  Aggregate
                </TableCell>
                {repeatedConfig?.map((col: any, j: number) => (
                  <TableCell
                    key={j}
                    sx={{
                      textTransform: "capitalize",
                      textAlign: !col.aggregateType ? "center" : "auto",
                    }}
                  >
                    {col.aggregateType ? (
                      <AggregateField
                        currentQuestion={currentQuestion}
                        index={j}
                        type={"column"}
                        aggregateType={col.aggregateType}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                ))}
                {rowAggregate && (
                  <TableCell sx={{ minWidth: "85px" }}></TableCell>
                )}
                <TableCell sx={{ display: "flex", gap: "10px" }}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <RpSave id={currentQuestion.id} />
    </Stack>
  );
};

const RpSave = ({ id }: { id: string }) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.formEditable);
  const isFieldSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  const handleBlur = () => {
    if (id) {
      dispatch(
        triggerSave({
          studyId,
          id: id,
          surveySlug,
        })
      );
    }
  };

  return (
    <Button
      disabled={!!isFieldSubmitting || isPreview}
      variant="contained"
      onClick={handleBlur}
      sx={{ width: "fit-content", alignSelf: "flex-end" }}
    >
      Save
    </Button>
  );
};

export default RepeatedField;
