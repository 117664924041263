import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ExerciseCollectionState {
  urlLoaded: boolean;
  loading: boolean;
  type: string;
  page: number;
  collectionPage: number;
  searchText: string;
  exerciseCollectionData: any[];
  exerciseCollectionDataCount: number;
  exerciseLibraryData: any[];
  exerciseLibraryDataCount: number;
  exerciseCollectionModalId: string | null;
  exerciseCategoryModalId: string | null;
  toggler: boolean;
}

const initialState: ExerciseCollectionState = {
  urlLoaded: false,
  loading: true,
  type: "collection",
  searchText: "",
  page: 0,
  collectionPage: 0,
  exerciseCollectionData: [],
  exerciseCollectionDataCount: 0,
  exerciseLibraryData: [],
  exerciseLibraryDataCount: 0,
  exerciseCollectionModalId: null,
  exerciseCategoryModalId: null,
  toggler: false,
};

export const exerciseCollectionSlice = createSlice({
  name: "exerciseCollection",
  initialState: initialState,
  reducers: {
    setExerciseCollectionLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setExerciseCollectionData: (state, action: PayloadAction<any>) => {
      state.exerciseCollectionData = action.payload;
    },
    setExerciseCollectionDataCount: (state, action: PayloadAction<any>) => {
      state.exerciseCollectionDataCount = action.payload;
    },
    setExerciseLibraryData: (state, action: PayloadAction<any>) => {
      state.exerciseLibraryData = action.payload;
    },
    setExerciseLibraryDataCount: (state, action: PayloadAction<any>) => {
      state.exerciseLibraryDataCount = action.payload;
    },
    setExerciseCollectionModalId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.exerciseCollectionModalId = action.payload;
    },
    setExerciseCategoryModalId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.exerciseCategoryModalId = action.payload;
    },
    toggleExerciseCollection: (state) => {
      state.toggler = !state.toggler;
    },
    setExercisePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCollectionPage: (state, action: PayloadAction<number>) => {
      state.collectionPage = action.payload;
    },
    setExerciseType: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = "";
      state.type = action.payload;
    },
    setExerciseSearchText: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = action.payload;
    },
    setExerciseDefaults: (
      state,
      action: PayloadAction<{
        page: number;
        type: string;
        searchText: string;
      }>
    ) => {
      state.type = action.payload.type;
      state.page = action.payload.page;
      state.searchText = action.payload.searchText;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    reset: () => initialState,
  },
});

export const {
  setExerciseCollectionLoader,
  setExerciseCollectionData,
  setExerciseCollectionDataCount,
  setExerciseLibraryData,
  setExerciseLibraryDataCount,
  setExerciseCollectionModalId,
  setExerciseCategoryModalId,
  toggleExerciseCollection,
  setExercisePage,
  setCollectionPage,
  setExerciseType,
  setExerciseSearchText,
  setExerciseDefaults,
  setUrlLoadedFalse,
  reset,
} = exerciseCollectionSlice.actions;

export default exerciseCollectionSlice.reducer;
