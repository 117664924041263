import { CloseRounded } from "@mui/icons-material";
import { Box, Divider, IconButton, SxProps, Typography } from "@mui/material";

type DrawerProps = {
  closeDrawer: () => void;
  title: string;
};

export const DrawerHeader = ({ closeDrawer, title }: DrawerProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "25px",
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          textTransform={"capitalize"}
          color="primary.main"
        >
          {title}
        </Typography>
        <IconButton onClick={closeDrawer}>
          <CloseRounded sx={{ color: "text.secondary" }} />
        </IconButton>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
    </>
  );
};

export const CommonDrawerStyle: SxProps = {
  width: "900px",
  p: 2.5,
  height: "calc(100% - 51px)",
};

export const DrawerForm: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
