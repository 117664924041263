import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import isEmail from "validator/es/lib/isEmail";

const adminOptions = [
  { label: "Org Admin", value: "organisation_admin" },
  { label: "Admin", value: "admin" },
];

// const studyTypeOptions = [
//   { label: "Production", value: "production" },
//   { label: "Test", value: "test" },
//   { label: "Example", value: "example" },
// ];

let schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),

  lastName: yup.string().required("Last Name is Required"),
  phoneNumber: yup.string().required("Phone Number is Required"),
  email: yup
    .string()
    .required("*Email ID is Required")
    .test("is-valid", "*Please enter a valid Email ID.", (value) =>
      value ? isEmail(value) : false
    ),
  empId: yup.string().required("Emp Id is Required"),
  role: yup.string().required("Role is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const AddNewUserModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        empId: values?.empId,
        role: values?.role,
      };
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.patch(`/study/${data?.id}`, body);
      } else {
        res = await http.post(`/study`, body);
      }

      toastMessage("success", res?.data?.message);
      closeModal();
      setSubmitLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={data?.id ? "Edit User" : "Create User"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            phoneNumber: data?.phoneNumber || "",
            email: data?.email || "",
            empId: data?.empId || "",
            role: data?.role || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ getFieldProps, errors, touched, setFieldValue, values }) => (
            <Form>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="first-name">
                    First Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="First name"
                    id="first-name"
                    {...getFieldProps("firstName")}
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="last-name">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="Last name"
                    id="last-name"
                    {...getFieldProps("lastName")}
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="phone-number">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="Phone number"
                    id="phone-number"
                    {...getFieldProps("phoneNumber")}
                    error={
                      touched?.phoneNumber && errors?.phoneNumber ? true : false
                    }
                    helperText={
                      touched?.phoneNumber && errors?.phoneNumber
                        ? (errors?.phoneNumber as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="Email"
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="empId">
                    EMP ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="EMP ID"
                    id="emp-id"
                    {...getFieldProps("empId")}
                    error={touched?.empId && errors?.empId ? true : false}
                    helperText={
                      touched?.empId && errors?.empId
                        ? (errors?.empId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="role">
                    Status <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    value={values.role}
                    onChange={(e) => {
                      setFieldValue("role", e.target.value);
                    }}
                    fullWidth
                    id="role"
                  >
                    {adminOptions.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    error={touched?.role && errors?.role ? true : false}
                  >
                    {touched?.role && errors?.role
                      ? (errors?.role as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddNewUserModal;
